<template>
  <div>
    <v-container fluid class="fondo fb" id="fondo">
      <v-row align="center" justify="center">
        <v-col md="3" class="mt-6 mb-2">
          <v-img src="./../../assets/imgDemo2/logo.png" />
          <br />
          <h3 class="text-left hidden-md-and-up">
              Balance Metalúrgico AMSA
          </h3>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <h3 class="text-right hidden-sm-and-down">
            Balance Metalúrgico AMSA
          </h3>
        </v-col>
      </v-row>
      <v-row justify="center">

        <template v-if="opcion==0">
          <v-col cols="12" sm="10" md="10" lg="6" xl="6">
              <ChatWindow @cambio="cambio = !cambio" nombre="AssistantDemo2" />
            </v-col>
        </template>

        <template v-else-if="opcion!=0">
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <ChatWindow @cambio="cambio = !cambio" nombre="AssistantDemo2" />
            </v-col>
          <v-col cols="12" sm="8" md="8" lg="8" xl="8">
            <template v-if="opcion==1">
              <v-card elevation="5" class="borderColorC">
                <v-card-title class="white--text"> Documentos relacionados. </v-card-title>
                <v-divider></v-divider>
                <template v-if="viewComponentDocs">
                  <v-row>
                    <br>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">

                      <template v-for="i of iCard">
                        <v-card class="mx-auto cardR transparente" dark :key="i.id" >
                        <v-card-title>
                          <v-icon large left dark color="gray">
                            {{i.icono}}
                          </v-icon>
                          <span class="white--text">{{i.tipo}}</span>
                        </v-card-title>
                        <v-card-text class="white--text">
                          {{i.oracion}}
                        </v-card-text>
                      </v-card>
                      </template>
                    </v-col>
                    <template v-for="d of documentos">
                      <v-col cols="12" sm="6" md="4" lg="2" xl="2" :key="d.id">
                        <v-card-text class="cardDocs">
                          <DocsCard :datos="d" />
                        </v-card-text>
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </v-card>
            </template>
            <template v-else-if="opcion==2">
              <v-card elevation="5" height="600" class="borderColorC scroll contenedor">
                <v-card-title class="white--text"> Graficos relacionados. </v-card-title>
                <v-divider></v-divider>
                <template v-if="viewComponentChart">
                  <v-expansion-panels popout >
                    <v-expansion-panel class="transparente white--text"
                      v-for="item of datosC[0]"
                      :key="item.i"
                    >
                      <v-expansion-panel-header class="white--text">{{item}}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <HistogramaChart :datosC="item"/>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-card>
            </template>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ChatWindow from "../../components/Chat/ChatWindow";
import DocsCard from "../../components/Docs/DocsCard";
import ChartExample from "../../components/Charts/ChartExample";
import PieChart from "../../components/Charts/PieChart";
import XYChart from "../../components/Charts/XYChart";
import HistogramaChart from "../../components/Charts/HistogramaChart"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  components: {
    ChatWindow,
    DocsCard,
    ChartExample,
    PieChart,
    XYChart,
    HistogramaChart
  },
  data() {
    return {
      cambio: false,
      viewComponentDocs: false,
      viewComponentChart: false,
      documentos: [],
      datosC: [],
      opcion: 0,
      iCard: []
    };
  },
  async mounted() {
    await this.obtenerDatos();
    this.cambiarFondo("assets/imgDemo2/salaControl.jpg");
  },
  computed: {
    ...mapState(["logMessages", "datosG", "url"]),
    ...mapGetters(["obtenerDatosDocsNombre", "obtenerMensajes", "obtenerCharts", "obtenerDatosDocs"]),
  },
  watch: {
    cambio: function () {
      this.mostrarComponentes();
    },
  },
  methods: {
    destroyed() {
      this.clearStorage();
    },
    ...mapMutations(["entorno", "saveMsg"]),
    ...mapActions(["obtenerDatos"]),
    asignarDocs(documentos) {
      this.documentos = documentos;
      this.viewComponentDocs = true;
    },
    asignarCharts() {
      this.datosC.push(arguments)
      this.viewComponentChart = true;
    },
    graficas(){
      this.asignarCharts(
        "Alimentación de cobre",
        "Recuperación de Cobre 1",
        "Recuperación de Cobre 2",
        "Cobre Filtrado",
        "Toneladas Métricas Finales Cobre",
      );
    },
    cleanAll() {
      this.opcion = 0;
      if (this.viewComponentDocs) {
        this.viewComponentDocs = false;
      }
      if (this.viewComponentChart) {
        this.viewComponentChart = false;
      }
    },
    dateFormData(año, mes) {
      var data = new FormData();
      data.append("año", año);
      data.append("mes", mes);
      return data;
    },
    async discovery(query, cantidad, filter, returnV) {
      var datos = [];
      this.entorno("Discovery");
      try {
        var data = new FormData();
        data.append("query", query);
        data.append("count", cantidad);
        data.append("filter", filter);
        data.append("return", returnV);
        data.append("conexion", "DiscoveryDemo2");
        await axios.post(this.url, data).then((dataR) => {
          datos = dataR;
        });
      } catch (error) {
        console.error(error);
      }finally{
        return datos;
      }
    },
    strcmp ( str1, str2 ) {
      return ( ( str1 == str2 ) ? 0 : ( ( str1 > str2 ) ? 1 : -1 ) );
    },
    async getBalance(año, mes) {
      var datos = [];
      try {
        this.entorno("Discovery/Consulta");
        await axios.post(this.url, this.dateFormData(año, mes)).then((result) => {
          datos =  result.data;
        });
      } catch (error) {
        console.error(error);
      } finally{
        return datos;
      }
    },
    encode(str){
      return window.btoa(unescape(encodeURIComponent( str )));
    },
    decode(str){
      return decodeURIComponent(escape(window.atob( str )));
    },
    setStorage(option, dato){
      if(option=="tipo"){
        window.localStorage.setItem("tipo", this.encode(dato))
      }else if (option=="anterior") {
        window.localStorage.setItem("anterior", this.encode(dato))
      }
    },
    getStorage(item){
      return this.decode(window.localStorage.getItem(item));
    },
    clearStorage(){
      window.localStorage.clear();
    },
    cambiarMensaje(mensaje){
      this.logMessages[this.logMessages.length - 1].mensaje = mensaje;
    },
    getDate() {
      return `${new Date().getHours()}:${new Date().getMinutes()}`;
    },
    async addImagen(id, from, mensaje, data) {
      try {
        await this.saveMsg({
          id: id,
          from: from,
          mensaje: mensaje,
          datos: data,
          hora: this.getDate(),
        });
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    llenarTarjetas(tipo, oracion, icon = "mdi-checkbox-blank-circle"){
      this.iCard.push({
        tipo: tipo,
        oracion: oracion,
        icono: icon
      })
    },
    cambiarFondo(imagen){
     document.getElementById("fondo").style.backgroundImage = 'url("'+ imagen + '")'
    },
    eliminarDuplicados(value, index, self){
      return self.indexOf(value) === index;
    },
    verificarDuplicados(array){
      var repetidos = {};
      var arreglo = [];
      // TODO: Algoritmo countSort
      array.forEach(function(valor){
        repetidos[valor] = (repetidos[valor] || 0) + 1;
      })
      var aux = Object.keys(repetidos);
      for (let i = 0; i < aux.length; i++) {
        arreglo.push({
          oracion: aux[i],
          cantidad: repetidos[aux[i]]
        })
      }
      return arreglo;
    },
    async mostrarComponentes() {
      this.iCard = [], this.documentos=[];
      var entities = [],
        mes;
      var datos = this.logMessages[this.logMessages.length - 1].datos;
      for (let index = 0; index < datos.entities.length; index++) {
        if (datos.entities[index].entity == "sys-number") {
          entities['year']=datos.entities[index].value;
        } else if (datos.entities[index].entity == "meses") {
          mes = datos.entities[index].value;
          entities[datos.entities[index].entity]=datos.entities[index].value;
        } else {
          entities[datos.entities[index].entity]=datos.entities[index].value;
        }
      }
      this.cambiarFondo("assets/imgDemo2/salaControl.jpg");
      switch (this.logMessages[this.logMessages.length - 1].datos.output.nodes_visited[0].toString()) {
        case "node_4_1568400988710":
          this.cambiarFondo("assets/imgDemo2/minaCentinela.jpg");
          if (typeof entities.year !== "undefined" && typeof entities.meses !== "undefined") {
            var datosBD = []
            await this.getBalance(entities.year, entities.meses).then((datos=>{datosBD = datos}))
            if (typeof entities.cobre_fino !== "undefined") {
              this.cambiarMensaje(`El total de producción de cobre fino es ${datosBD.conCuTmf}. ¿Te gustaria hacer una comparacion con otra fecha?.`)
              this.setStorage("tipo", 1);
              this.setStorage("anterior", datosBD.conCuTmf);
            } else if (typeof entities.molibdeno_fino !== "undefined") {
              this.cambiarMensaje(`El total de producción de molibdeno fino es ${datosBD.conMoTmf}. ¿Te gustaria hacer una comparacion con otra fecha?.`)
              this.setStorage("tipo", 2);
              this.setStorage("anterior", datosBD.conMoTmf);
            } else if (typeof entities.concentrado_filtrado !== "undefined") {
              if (typeof entities.cobre !== "undefined") {
                this.cambiarMensaje(`El total de produccion de cobre concentrado filtrado de CU ${datosBD.conCuFiltrado}. ¿Te gustaria hacer una comparacion con otra fecha?.`);
                this.setStorage("tipo", 3);
                this.setStorage("anterior", datosBD.conCuFiltrado);
              } else if (typeof entities.molibdeno !== "undefined") {
                this.cambiarMensaje(`El total de produccion de cobre concentrado filtrado de MO ${datosBD.conMoFiltrado}. ¿Te gustaria hacer una comparacion con otra fecha?.`);
                this.setStorage("tipo", 4);
                this.setStorage("anterior", datosBD.conMoFiltrado);
              } else {
                this.cambiarMensaje("No se encontro información.");
              }
            } else if (typeof entities.cobre !== "undefined") {
              this.cambiarMensaje(`El total de mineral procesado es ${datosBD.minProcesado} mil TMS el porcentaje de CU es ${(datosBD.proCu*100)}%. ¿Te gustaria hacer una comparacion con otra fecha?.`);
              this.setStorage("tipo", 5);
              this.setStorage("anterior", datosBD.proCu);
            } else if (typeof entities.molibdeno !== "undefined") {
              this.cambiarMensaje(`El total de mineral procesado es ${datosBD.minProcesado} mil TMS el porcentaje de MO es ${(datosBD.proMo*100)}%. ¿Te gustaria hacer una comparacion con otra fecha?.`);             
              this.setStorage("tipo", 6);
              this.setStorage("anterior", datosBD.proMo);              
            } else {
              this.cambiarMensaje(`El total de mineral procesado es ${datosBD.minProcesado} mil TMS. ¿Te gustaria hacer una comparacion con otra fecha?.`);
              this.setStorage("tipo", 7);
              this.setStorage("anterior", datosBD.minProcesado);     
            }
            } else {
              this.cambiarMensaje("No se encontro fecha para consultar");
            }
          this.opcion = 0;
          break;
        case "node_10_1568911410474":
          if (typeof entities.year !== "undefined" && typeof entities.meses !== "undefined") {
            var datosBD = []
            await this.getBalance(entities.year, entities.meses).then((datos=>{datosBD = datos}))
            if (typeof entities.cobre !== "undefined") {
              this.cambiarMensaje(`La ley de cobre del material procesado filtrado ${datosBD.filCu}%. ¿Te gustaria hacer una comparacion con otra fecha?.`);
              this.setStorage("tipo", 8);
              this.setStorage("anterior", datosBD.filCu);
            }else if(typeof entities.molibdeno !== "undefined"){
              this.cambiarMensaje(`La ley de cobre del molibdeno procesado filtrado ${datosBD.filMo}%. ¿Te gustaria hacer una comparacion con otra fecha?.`);
              this.setStorage("tipo", 9);
              this.setStorage("anterior", datosBD.filMo);
            }
          }
          this.opcion = 0;
          break;
        case "node_10_1568911544687":
          this.cambiarFondo("assets/imgDemo2/CatodosCU.jpg");
            if (typeof entities.year !== "undefined" && typeof entities.meses !== "undefined") {
              var datosBD = []
              await this.getBalance(entities.year, entities.meses).then((datos=>{datosBD = datos}))
              this.cambiarMensaje(`El total de recuperación obtenida de CU ${(datosBD.reCu)*100}% el total de MO es  ${(datosBD.reMo)*100}%. ¿Te gustaria hacer una comparacion con otra fecha?.`);
              this.setStorage("tipo", 10);
              this.setStorage("anterior", datosBD.reCu);
          }
        this.opcion = 0;
        break;
        case "node_4_1569295329576":
        case "node_2_1569297387179":
        case "node_6_1569297452391":
          if (typeof entities.year !== "undefined" && typeof entities.meses !== "undefined") {
              var datosBD = []
              await this.getBalance(entities.year, entities.meses).then((datos=>{datosBD = datos}))
              switch (parseInt(this.getStorage("tipo"))) {
                case 1:
                  var texto = "";
                  texto += `La producción de cobre fino es ${datosBD.conCuTmf}. \n`;
                  if (datosBD.conCuTmf > this.getStorage("anterior")) {
                    texto += `Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.conCuTmf}%.`;
                  }else{
                    texto += `Con una baja del ${100-((datosBD.conCuTmf)*100)/this.getStorage("anterior")}%.`;
                  }
                  this.cambiarMensaje(texto);
                  break;
                case 2:
                  var texto = "";
                  texto += `La producción de molibdeno fino es ${datosBD.conMoTmf}.\n`;
                  if (datosBD.conMoTmf > this.getStorage("anterior")) {
                    texto += `Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.conMoTmf}%.`;
                  }else{
                    texto += `Con una baja del ${100-((datosBD.conMoTmf)*100)/this.getStorage("anterior")}%.`;
                  }
                  this.cambiarMensaje(texto);
                  break;
                case 3:
                  var texto = "";
                  texto += `La producción de cobre concentrado de CU ${datosBD.conCuFiltrado}. \n`
                  if (datosBD.conCuFiltrado > this.getStorage("anterior")) {
                    texto += `Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.conCuFiltrado}%.`;
                  }else{
                    texto += `Con una baja del ${100-((datosBD.conCuFiltrado)*100)/this.getStorage("anterior")}%.`;
                  }
                  this.cambiarMensaje(texto)
                  break;
                case 4:
                  var texto = "";
                  texto += `La producción de cobre concentrado de CU ${datosBD.conMoFiltrado}. \n`
                  if (datosBD.conMoFiltrado > this.getStorage("anterior")) {
                    texto += `Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.conMoFiltrado}%.`
                  }else{
                    texto += `Con una baja del ${100-((datosBD.conMoFiltrado)*100)/this.getStorage("anterior")}%.`
                  }
                  this.cambiarMensaje(texto);
                  break;
                case 5:
                  var texto = "";
                  texto += `El mineral procesado es ${datosBD.minProcesado} mil TMS el procentaje de CU es ${datosBD.proCu*100}%. \n`;
                  if (datosBD.proCu > this.getStorage("anterior")) {
                    texto += `Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.proCu}%.`;
                  }else{
                    texto += `Con una baja del ${100-((datosBD.proCu)*100)/this.getStorage("anterior")}%.`;
                  }            
                  this.cambiarMensaje(texto);
                  break;
                case 6:
                  var texto = "";
                  texto += `El mineral procesado es ${datosBD.minProcesado} mil TMS el procentaje de CU es ${datosBD.proMo*100}%. \n`;
                  if (datosBD.proCu > this.getStorage("anterior")) {
                    texto += `Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.proCu}%.`;
                  }else{
                    texto += `Con una baja del ${100-((datosBD.proCu)*100)/this.getStorage("anterior")}%.`;
                  }      
                  this.cambiarMensaje(texto);        
                  break;
                case 7:
                  var texto = "";
                  texto += `El mineral procesado es ${datosBD.minProcesado} mil TMS. \n`;
                  if (datosBD.minProcesado > this.getStorage("anterior")) {
                    texto += `Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.minProcesado}%.`;
                  }else{
                    texto += `Con una baja del ${100-((datosBD.minProcesado)*100)/this.getStorage("anterior")}%.`;
                  }              
                  this.cambiarMensaje(texto);
                  break;
                case 8:
                  var texto = "";
                  texto += `La ley de cobre del material procesado filtrado ${datosBD.filCu}%. \n`;
                  if (datosBD.minProcesado > this.getStorage("anterior")) {
                    texto += `Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.minProcesado}%.`;
                  }else{
                    texto += `Con una baja del ${100-((datosBD.minProcesado)*100)/this.getStorage("anterior")}%.`;
                  }        
                  this.cambiarMensaje(texto);      
                  break;
                case 9:
                  var texto = "";
                  texto += `La ley de molibdeno del material procesado filtrado ${datosBD.filMo}%. \n`;
                  if (datosBD.filMo > this.getStorage("anterior")) {
                    texto +=`Con un incremento del ${(this.getStorage("anterior")*100)/datosBD.filMo}%.`
                  }else{
                    texto += `Con una baja del ${100-((datosBD.filMo)*100)/this.getStorage("anterior")}%.`;
                  }              
                  this.cambiarMensaje(texto);
                  break;
                case 10:
                  this.cambiarMensaje(`El total de recuperación obtenida de CU (${datosBD.reCu*100})% el total de MO es (${datosBD.reMo*100})%.`);
                  break;               
              }
          }
          this.opcion = 0;
          break;
        case "node_3_1571066338931":
          //NODO LISTO!
          this.cambiarFondo("assets/imgDemo2/caexCentinela.jpg");
          var resultSentence = "", result = "", documento, contador = 0, passages = "";
          await this.discovery("text:caolin", 10, "&passages=true", "enriched_text,extracted_metadata").then((datosD=>{result = datosD.data}));
          var conteoResult = (typeof result.passages !== "undefined") ? result.passages.length: 0;
          for (let index = 0; index < conteoResult; index++) {
            contador++;
            passages = result.passages[index].passage_text; 
            documento = result.results[0].extracted_metadata.filename;
            resultSentence +=`<p> En el ${documento} se encontró la siguiente información: ${passages} </p>`
            this.llenarTarjetas("Resultado", `En el ${documento} se encontró la siguiente información: ${passages}`)
          }
          resultSentence += `<hr>`
          this.cambiarMensaje(`Se encontraron ${contador} resultados en el documento "${documento}".`)
          this.asignarDocs(this.obtenerDatosDocsNombre(documento));
          this.opcion = 1;
          break;
        case "node_10_1571067780151":
          //NODO LISTO
          var resultSentence = "", result = "", textos = [], documento = "";
          var query = `enriched_text.entities.type::"DESVIACION",enriched_text.entities.type::"DETENCION",text:${mes}`
          await this.discovery(query, 20, "&passages=true", "enriched_text,extracted_metadata").then((datosD=>{result = datosD.data}));
          var conteo = (typeof result.results !== "undefined") ? result.results.length: 0;
          for (let index = 0; index < conteo; index++) {
            var arrayEnrich = result.results[index].enriched_text;
            var conteoRelation = typeof arrayEnrich.relations !== "undefined" ? arrayEnrich.relations.length : 0;
            for (let j = 0; j < conteoRelation; j++) {
              var type0 = arrayEnrich.relations[j].arguments[0].entities[0].type;
              var type1 = arrayEnrich.relations[j].arguments[1].entities[0].type;
              if (this.strcmp(type0, "DESVIACION") == 0 || this.strcmp(type1, "DETENCION")==0) {
                documento = result.results[index].extracted_metadata.filename;
                resultSentence = `En el ${documento} se encontró la siguiente información: ${arrayEnrich.relations[j].sentence}`;
                textos[j] = resultSentence;
              }
            }
          }
          resultSentence = "";
          var contador = 0;
          textos.forEach(element => {
            contador++;
            this.llenarTarjetas("Detención o desviación", element)
          });  
          this.asignarDocs(this.obtenerDatosDocsNombre(documento));
          this.cambiarMensaje(`Se encontraron ${contador} resultados en el documento ${documento}`);
          this.opcion = 1;
          break;

        case "node_10_1571067801221":
          //NODO LISTO.
            var resultSentence = "", result = "", documento = "", conteo = 0, textos = [];
            var query = `enriched_text.entities.type::"INCIDENTE",text:"${mes}"`;
            await this.discovery(query, 20, "&passages=true", "enriched_text,extracted_metadata").then((datosD=>{result = datosD.data}));
            conteo = (typeof result.results !== "undefined") ? result.results.length: 0;

            for (let index = 0; index < conteo; index++) {
              var arrayEnrich = result.results[index].enriched_text;
              var conteoRelation = typeof arrayEnrich.relations !== "undefined" ? arrayEnrich.relations.length : 0;

              for (let j = 0; j < conteoRelation; j++) {
                var entitiesRelation = arrayEnrich.relations[j].type
                if (this.strcmp(entitiesRelation, "ProblemaDe")==0) {
                  documento = result.results[index].extracted_metadata.filename;
                  resultSentence =`En el ${documento} se encontró la siguiente información: ${arrayEnrich.relations[j].sentence}`
                  textos[j] = resultSentence
                  this.asignarDocs(this.obtenerDatosDocsNombre(documento));
                }
              }         
            }
            resultSentence = "";
            var contador = 0;
            textos.filter(this.eliminarDuplicados).forEach(element => {
              contador++;
              resultSentence += element;
              this.llenarTarjetas("Afectación", element)
            });
            this.cambiarMensaje(`Se encontraron ${contador} resultados en el documento: ${documento}`);    
            this.opcion = 1;
        break;
        case "node_10_1571067815351":
          //NODO LISTO
            var resultSentence = "", result = "", documento = "", conteo = 0, textos = [];
            var query = `enriched_text.entities.type::"PROBLEMA",text:"${mes}"`;
            await this.discovery(query, 20, "&passages=true", "enriched_text,extracted_metadata").then((datosD=>{result = datosD.data}));
            conteo = (typeof result.results !== "undefined") ? result.results.length: 0;

            for (let index = 0; index < conteo; index++) {
              var arrayEnrich = result.results[index].enriched_text;
              var conteoRelation = typeof arrayEnrich.entities !== "undefined" ? arrayEnrich.entities.length : 0;
              for (let j = 0; j < conteoRelation; j++) {
                var entitiesRelation = arrayEnrich.entities[j].type
                if (this.strcmp(entitiesRelation, "PROBLEMA")==0) {
                  documento = result.results[index].extracted_metadata.filename;
                  resultSentence =`En el ${documento} se encontró la siguiente información: ${arrayEnrich.entities[j].text}`
                  textos[j] = resultSentence
                  this.asignarDocs(this.obtenerDatosDocsNombre(documento));
                }
              }         
            }

            resultSentence = "";
            var contador = 0;
            textos.filter(this.eliminarDuplicados).forEach(element => {
              contador++;
              resultSentence += element;
              this.llenarTarjetas("Problema", element, "mdi-alert")
            });
            this.cambiarMensaje(`Se encontraron ${contador} resultados en el documento: ${documento}`);    
            this.opcion = 1;
          break;

        case "node_7_1571067880961":
          //NODO LISTO
          var resultSentence = "", result = "", documento = "", conteo = 0, textos = [];
          var query = `enriched_text.entities.type::"INCIDENTE"`;
          await this.discovery(query, 20, "&passages=true", "enriched_text,extracted_metadata").then((datosD=>{result = datosD.data}));
          conteo = (typeof result.results !== "undefined") ? result.results.length: 0;   
          for (let index = 0; index < conteo; index++) {
            var arrayEnrich = result.results[index].enriched_text;
            var conteoRelation = typeof arrayEnrich.relations !== "undefined" ? arrayEnrich.relations.length : 0;
            for (let j = 0; j < conteoRelation; j++) {
              var entitiesRelation = arrayEnrich.relations[j].arguments[1].entities[0].text;
              var relationType = arrayEnrich.relations[j].type;
              if (this.strcmp(relationType, "ProblemaDe")==0) {
                var type = arrayEnrich.relations[j].arguments[1].entities[0].type;
                if (this.strcmp(type, "SECTOR")==0) {
                  var sector = arrayEnrich.relations[j].arguments[1].entities[0].text;
                  textos.push(sector)
                  documento = result.results[index].extracted_metadata.filename;
                }
              }
            }         
          }
          var aux = this.verificarDuplicados(textos).sort(function (a, b) {
            return a.cantidad - b.cantidad;
          });
          var max = aux.length, sectorMax= "", contador = 0;
          max = max - 3;

          for (let i = 0; i < aux.length; i++) {
            contador ++;     
            sectorMax = aux[i].oracion;
            if (contador>max) {
              resultSentence += `<p>Sector: ${aux[i].oracion}. <br> Referencias: ${aux[i].cantidad}."</p><br>`
            }
          }
          textos = [];

          for (let index = 0; index < conteo; index++) {
            arrayEnrich = result.results[index].enriched_text;
            var conteoRelation = typeof arrayEnrich.relations !== "undefined" ? arrayEnrich.relations.length : 0;
            for (let j = 0; j < conteoRelation; j++) {
              var relationType = arrayEnrich.relations[j].type;
              if (this.strcmp(relationType, "ProblemaDe")==0) {
                var type = arrayEnrich.relations[j].arguments[1].entities[0].type;
                var relationSector2 = arrayEnrich.relations[j].arguments[1].entities[0].text;
                if (this.strcmp(type, "SECTOR")==0) {
                  if (this.strcmp(relationSector2, sectorMax) == 0) {
                    var sector = arrayEnrich.relations[j].sentence;
                    textos.push(sector);
                  }
                }
              }
            }
          }
          var resultDocumentos = "";
          var aux = this.verificarDuplicados(textos)
          for (let i = 0; i < aux.length; i++) {
            resultDocumentos += `<p>Incidente: ${aux[i].oracion}</p>`
            this.llenarTarjetas("Incidente", `${aux[i].oracion}`, "mdi-alert-circle")
          }

          this.asignarDocs(this.obtenerDatosDocsNombre(documento));
          this.cambiarMensaje(`${resultSentence}`);    
          this.addImagen(Date.now(), "Asistente", `<img src="assets/imgDemo2/molinoBolas.png" alt="Molino" height="250vh">`, null)
          this.opcion = 1;
        break;
        case "node_1_1571067840727":
          var resultSentence = "", result = "", documento = "", conteo = 0, textos = [];
          var query = `enriched_text.entities.type::"DESVIACION",text:"${mes}"`;
          await this.discovery(query, 20, "&passages=true", "enriched_text,extracted_metadata").then((datosD=>{result = datosD.data}));
          conteo = (typeof result.results !== "undefined") ? result.results.length: 0;
          for (let index = 0; index < conteo; index++) {
            var arrayEnrich = result.results[index].enriched_text;
            var conteoRelation = typeof arrayEnrich.relations !== "undefined" ? arrayEnrich.relations.length : 0;
            for (let j = 0; j < conteoRelation; j++) {
              var type = arrayEnrich.relations[j].arguments[0].entities[0].type;
              var type2 = arrayEnrich.relations[j].arguments[1].entities[0].type;
              if (this.strcmp(type, "DESVIACION")==0 || this.strcmp(type2, "DESVIACION")==0 ||  this.strcmp(type, "DETENCION") == 0 || this.strcmp(type2, "DETENCION") == 0) {
                var sector = arrayEnrich.relations[j].sentence;
                textos.push(sector)
                documento = result.results[index].extracted_metadata.filename;
              }
            }         
          }
          var aux = this.verificarDuplicados(textos), contador = 0;
          for (let i = 0; i < aux.length; i++) {
            contador++;
            resultSentence += `<p>Problema: ${aux[i].oracion}, referencias: ${aux[i].cantidad}</p>`;
            this.llenarTarjetas("Problema", `${aux[i].oracion}, referencias: ${aux[i].cantidad}`, "mdi-alert")
          }
          this.asignarDocs(this.obtenerDatosDocsNombre(documento));
          this.cambiarMensaje(`Se encontraron ${contador} resultados en el documento: ${documento}`);    
          this.opcion = 1;
          break;
        case "node_2_1571066409203":
          this.cambiarMensaje("La predicción del mes de febrero se muestra en el panel derecho.");
          this.setStorage("tipo", 1);
          this.graficas();
          this.opcion = 2;
          break;
        default:
          this.cleanAll();
          break;
      }
      entities = [];
    },
  },
};
</script> 

<style>
.borderColorC {
  border-style: solid !important;
  border-width: 2px !important;
  border-color: black !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.cardDocs {
  height: auto;
}
.scroll {
  overflow-y: scroll !important;
}
.titulo{
  margin-bottom: 10px;
}

.fondo{
  background-repeat: no-repeat; 
  padding: 0rem 2rem;
  color: #000000;
  background-color: #000000;
  padding-bottom: 10.85%; /* for a 4:3 aspect ratio */
  background-size: 100vw auto;
}
.transparente{
    background-color: rgba(0, 0, 0, 0) !important;
}
.cardR{
  margin: 20px !important;
}
.transparenteN{
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.fb{
  background-color: black !important;
}
</style>