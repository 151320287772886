import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
Vue.use(Vuex, axios)

export default new Vuex.Store({

  state: {
    loading: {
      titulo: "",
      estado: false,
      color: "",
    },
    idConversation: "",
    datosG: [],
    logMessages: [],
    url: ""
  },
  mutations: {
    entorno(state, servicio) {
      if (process.env.NODE_ENV === "development") {
        state.url = window.location.protocol + "//" + window.location.hostname + "/portafolio2021/public/" + servicio;
      } else {
        state.url = window.location.protocol + "//" + window.location.hostname + "/public/" + servicio;
      }
    },

    // TODO: Utilizado para mostrar u ocultar el loading.
    mostrarLoading(state, load) {
      state.loading.titulo = load.titulo;
      state.loading.estado = true
      state.loading.color = load.color;
    },
    ocultarLoading(state) {
      state.loading.estado = false;
    },

    // * Utilizado para settear los mensajes.
    saveMsg(state, mensajes) {
      state.logMessages.push({
        id: mensajes.id,
        from: mensajes.from,
        mensaje: mensajes.mensaje,
        datos: mensajes.datos,
        hora: mensajes.hora,
      })
    },

    // TODO: Utilizado para establecer el id.
    setID(state, id) {
      state.idConversation = id
    },

    async llenarDatos(state, datos) {
      try {
        await (state.datosG = datos);
      } catch (error) {
        console.error(error);
      }
    },
  },
  actions: {
    obtenerDatos: async function ({ commit }) {
      var datosP;
      await axios.get('assets/Data/Datos.json').then((data) => {
        datosP = data.data;
      })
      commit('llenarDatos', datosP)
    }
  },
  getters: {
    obtenerMensajes: state => { return state.logMessages },
    obtenerDatosDocs: (state) => (tipo) => {
      var aux = [];
      state.datosG.Documentos.forEach(element => {
        (element.tema == tipo) ?
          (
            aux.push(element)
          ) :
          "";
      });
      return aux;
    },
    obtenerDatosDocsNombre: (state) => (nombre) => {
      var aux = [];
      state.datosG.Documentos.forEach(element => {
        (element.nombre == nombre) ?
          (
            aux.push(element)
          ) :
          "";
      });
      return aux;
    },
    obtenerProyectos: state => { return state.datosG.Proyectos },
    obtenerProyectosTipo: (state) => (tipo) => {
      var aux = [];
      state.datosG.Proyectos.forEach(element => {
        (element.ruta == tipo) ?
          (
            aux.push(element)
          ) :
          "";
      });
      return aux;
    },
    obtenerCharts: (state) => (nombre) => {
      var aux = [];
      state.datosG.Charts.forEach(element => {
        (element.Nombre == nombre) ?
          (
            aux.push(element)
          ) :
          "";
      })
      return aux;
    }
  },
  modules: {
  },

})
