<template>
  <main>
    <v-container id="create">
      <v-speed-dial
        v-model="fab"
        :bottom="bottom"
        :right="right"
        :open-on-hover="hover"
      >
        <template #activator>
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
            @click="openDialog"
          >
            <v-icon>mdi-message</v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
    </v-container>
  </main>
</template>

<script>
export default {
  name: "ChatButton",

  data: () => ({
    fab: false,
    hover: false,
    tabs: null,
    right: true,
    bottom: true,
  }),
  methods: {
      openDialog(){
          const vm = this;
          vm.$emit("openDialog");
      }
  },
};
</script>
<style>
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>