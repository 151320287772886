<template>
  <main>
     <iframe style="position: absolute; height: 100%; border: none" width="100%" src="https://www.inndot.com/"
        scrolling="auto" frameborder="0" allowfullscreen/>
    <ChatButton @openDialog="openD = !openD" />
    <ChatPopUp
      :openD="openD"
      @cerrarD="openD = !openD"
      nombre="AssistantDemo1"
    />
  </main>
</template>
<script>
import ChatPopUp from "../../components/Chat/ChatPopUp";
import ChatButton from "../../components/Chat/ChatButton";
export default {
  components: {
    ChatPopUp,
    ChatButton,
  },
  methods: {},
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      openD: false,
    };
  },
};
</script>

<style>
</style>