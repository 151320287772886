<template>
  <main>
    <div v-html="texto" class="white--text">
    </div>
    <div
      ref="chartdiv"
      style="width: 100% !important; height: 500px !important"
    ></div>
  </main>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { mapActions, mapGetters, mapState } from "vuex";
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";

am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);
export default {
  name: "HistogramaChart",
  data() {
    return {
      chartData: [],
      datos: [],
      texto: "",
    };
  },
  props: {
    datosC: {
      type: String,
      required: false,
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  async mounted() {
    await this.obtenerDatos();
    var chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
    chart.responsive.enabled = true;
    this.generarChat(this.datosC, chart);
    chart.responsive.useDefault = false;
    chart.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelWidth <= 400) {
          return true;
        }
        return false;
      },
      state: function (target, stateId) {
        if (target instanceof am4charts.Chart) {
          var state = target.states.create(stateId);
          state.properties.paddingTop = 5;
          state.properties.paddingRight = 15;
          state.properties.paddingBottom = 5;
          state.properties.paddingLeft = 0;
          return state;
        }
        return null;
      },
    });
  },

  methods: {
    generarChat(opcion, chart) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.colors.step = 2;
      this.datos = this.obtenerCharts(opcion);
      this.texto = this.datos[0].texto
      this.generateChartData(this.datos[0].data);
      chart.data = this.chartData;
      chart.background.fill = "#000000";
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;
      for (let index = 0; index < this.datos[0].data.datasets.length; index++) {
        this.crearSeries(
          chart,
          this.datos[0].data.datasets[index].label,
          this.datos[0].data.datasets[index]
        );
      }
      chart.legend = new am4charts.Legend();
      chart.cursor = new am4charts.XYCursor();
      chart.language.locale = am4lang_es_ES;
      dateAxis.renderer.grid.template.disabled = !false;
      var inicio = this.datos[0].data.labels[0]
      var fin = this.datos[0].data.labels[10]
      chart.events.on("ready", function () {
        dateAxis.zoomToDates(
            new Date(inicio),
            new Date(fin)
          );    
      })
    },
    generateChartData(datos) {
      if (datos.datasets.length == 2) {
        for (let index = 0; index < datos.labels.length; index++) {
          this.chartData.push({
            date: datos.labels[index],
            [datos.datasets[0].label]: datos.datasets[0].data[index],
            [datos.datasets[1].label]: datos.datasets[1].data[index],
          });
        }
      } else if (datos.datasets.length == 3) {
        for (let index = 0; index < datos.labels.length; index++) {
          this.chartData.push({
            date: datos.labels[index],
            [datos.datasets[0].label]: datos.datasets[0].data[index],
            [datos.datasets[1].label]: datos.datasets[1].data[index],
            [datos.datasets[2].label]: datos.datasets[2].data[index],
          });
        }
      } else if (datos.datasets.length == 4) {
        for (let index = 0; index < datos.labels.length; index++) {
          this.chartData.push({
            date: datos.labels[index],
            [datos.datasets[0].label]: datos.datasets[0].data[index],
            [datos.datasets[1].label]: datos.datasets[1].data[index],
            [datos.datasets[2].label]: datos.datasets[2].data[index],
            [datos.datasets[3].label]: datos.datasets[3].data[index],
          });
        }
      }
    },
    crearSeries(chart, datos, opciones) {
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      if (chart.yAxes.indexOf(valueAxis) != 0) {
        valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
      }
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = datos;
      series.dataFields.dateX = "date";
      series.stroke = opciones.borderColor;
      series.name = opciones.label;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.tensionX = 0.8;
      series.showOnInit = true;
      if (typeof opciones.showLine == "undefined") {
        series.strokeWidth = 2;
      } else {
        series.strokeWidth = 0;
      }
      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = opciones.borderColor;
      bullet.circle.fill = opciones.borderColor;
    },
    ...mapActions(["obtenerDatos"]),
  },
  computed: {
    ...mapState(["datosG"]),
    ...mapGetters(["obtenerCharts"]),
  },
};
</script>

<style>
body {
  background-color: #30303d !important;
  color: #fff !important;
}

#chartdiv {
  width: 100% !important;
  height: 500px !important;
}
</style>