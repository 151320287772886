<template>
  <main>
    <v-footer dark app padless class="white--text text-center footerT">
      <v-card-text>
        <v-btn
          v-for="([icon, link], i) in data"
          :key="i"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px" @click="navegar(link)">
            {{ icon }}
          </v-icon>
        </v-btn>
        <v-divider></v-divider>
      </v-card-text>
      <v-container>
        <v-row>
          <v-col class="md-6">
            <v-card-text class="white--text pt-0">
              <h3>Sobre nosotros.</h3>
              Somos una empresa con más de 15 años de experiencia en manejo de
              datos, información y análisis para tu empresa
            </v-card-text>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="md-6">
            <v-card-text>
              <strong> DIRECCIÓN: </strong> José María Ibarrarán 84, San José
              Insurgentes, Benito Juárez, 03900 Ciudad de México, CDMX
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>INNDOT S.A de C.V</strong>
      </v-card-text>
    </v-footer>
  </main>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    data: [
      ["mdi-facebook", "https://www.facebook.com/inndotsadecv"],
      ["mdi-twitter", "https://twitter.com/inndotsadecv?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"],
      ["mdi-linkedin", "https://mx.linkedin.com/company/inndot"],
    ],
  }),
  methods: {
    navegar(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<style>
.footerT {
 position: absolute !important;
 bottom: 0 !important;
 width: 100% !important;
}
</style>