<template>
  <main>
    <v-dialog
      v-model="dato.estado"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet class="text-center">
          <v-btn
            class="transparente"
            color="black"
            rounded
            text
            v-bind="attrs"
            v-on="on"
            v-if="dato[0].ubi.includes('.pdf')"
          >
            Abrir
          </v-btn>
          <v-btn
            class="ma-1"
            text
            v-else-if="!dato[0].ubi.includes('.pdf')"
            @click="descargar"
          >
            Descargar
          </v-btn>
        </v-sheet>
      </template>

      <v-card>
        <v-toolbar dark dense>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              @click="dato.estado = !dato.estado"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-toolbar>
        <iframe
          frameborder="0"
          :src="this.dato[0].ubi"
          type="application/pdf"
          class="pdfTamaño"
        ></iframe>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
export default {
  name: "DocsModal",
  components: {},
  props: {
    dato: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pdf: false,
    };
  },
  mounted() {
    this.dato[0].ubi = "assets/Documents/" + this.dato[0].ubi;
  },
  methods: {
    descargar() {
      window.open(this.dato[0].ubi);
    },
  },
};
</script>

<style>
.pdfTamaño {
  width: 100%;
  height: 100vh !important;
}
</style>