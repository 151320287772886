<template>
  <v-app id="login" class="secondary">
    <v-main>
      <v-container fluid fill-height>
        <v-btn
          class="inndot1"
          dark
          absolute
          center
          left
          fab
          title="Volver"
          @click="$router.push('/')"
        >
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img
                    src="../assets/inndot.png"
                    alt="INNDOT"
                    width="180"
                    height="180"
                  />
                  <h1 class="flex my-4 inndot4--text">INNDOT</h1>
                </div>
                <v-form >
                  <v-text-field
                    color="inndot7"
                    name="login"
                    label="Correo"
                    type="text"
                    v-model="userEmail"
                    :error="error"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    color="inndot7"
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
                    name="password"
                    label="Contraseña"
                    id="password"
                    :rules="[rules.required]"
                    autocomplete="off"
                    v-model="password"
                    :error="error"
                    @click:append="hidePassword = !hidePassword"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn block color="inndot5" @click="login" :loading="loading"
                  >Inicio de sesión</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar v-model="showResult" :timeout="2000" top>
        {{ result }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      userEmail: "",
      password: "",
      hidePassword: true,
      error: false,
      showResult: false,
      result: "",
      rules: {
        required: (value) => !!value || "Dato necesario.",
      },
    };
  },

  methods: {
    login() {
      const vm = this;

      if (!vm.userEmail || !vm.password) {
        vm.result = "El usuario o la contraseña no pueden ir vacios.";
        vm.showResult = true;

        return;
      }

      if (
        vm.userEmail === vm.$root.userEmail &&
        vm.password === vm.$root.userPassword
      ) {
        vm.$router.push({ name: "Dashboard" });
      } else {
        vm.error = true;
        vm.result = "Error en las credenciales.";
        vm.showResult = true;
      }
    },
  },
};
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  background-image: url("https://www.inndot.com/wp-content/uploads/2020/12/data-science-back.jpg");
}
</style>
