<template>
  <v-container fluid class="pa-0">
    <v-parallax
      class="myParallax"
      src="@/assets/Proyectos/inteligencia-artificial-fondo.jpg"
    >
      <div class="parallax-filter"></div>
      <v-row align="center" justify="center" class="parallax-text">
        <v-col class="text-center" cols="11" sm="8">
          <h1 class="display-1 mb-4" v-scrollfadein>
            Inteligencia Artificial y Analítica Avanzada
          </h1>
          <h2 class="font-weight-thin mb-6" v-scrollfadein>
            Utilizar las nuevas tecnologías para tomar mejores decisiones a
            partir de la explotación inteligente de grandes cantidades de
            información y el aprendizaje del cómputo para apoyar en el día a día
            del negocio.
          </h2>
          <v-btn
            color="orange"
            x-large
            @click="$vuetify.goTo('#portafolio')"
            v-scrollfadein
            >Ver portafolio</v-btn
          >
          <v-sheet v-scrollfadein class="main-badge" color="transparent">
            <a href="https://openloop.org/" target="_black">
              <img src="@/assets/badges/openloop.png" alt="Openloop" />
            </a>
          </v-sheet>
        </v-col>
      </v-row>
    </v-parallax>
    <v-row class="mt-5 mb-3" id="portafolio" v-scrollfadein>
      <v-col class="justify-content-center text-center">
        <span class="my-heading">Portafolio</span>
      </v-col>
    </v-row>
    <v-row class="px-6 mb-6">
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(item, index) in demos"
        :key="index"
        v-scrollfadein
      >
        <new-card-demo :demo="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Data from "../assets/InfoDemos.json";
import NewCardDemo from "../components/Demos/NewCardDemo.vue";

export default {
  name: "Home2",
  components: {
    NewCardDemo,
  },
  data() {
    return {
      demos: Data.Proyectos,
    };
  },
};
</script>
