<template>
  <div class="card-demo">
    <div
      class="custom-badge"
      @click="openLink(demo.badge.url)"
      v-if="demo.badge"
    >
      <img :src="require('../../assets/badges/' + demo.badge.img)" />
    </div>
    <figure :class="cardHover ? 'snip1321 hover' : 'snip1321'">
      <!-- <img src="@/assets/Proyectos/portfolio-test.jpg" alt="sq-sample26" /> -->
      <img
        class="bg-image"
        :src="require('../../assets/Proyectos/' + demo.imagen_bg)"
        :alt="demo.solucion"
      />
      <figcaption class="title-caption">
        <div class="background"></div>
        <div class="title">
          <v-row>
            <v-col cols="12" class="py-0 my-0">
              <img
                :src="require('../../assets/Proyectos/' + demo.imagen)"
                :alt="demo.solucion"
                height="20"
              />
            </v-col>
            <v-col cols="12" class="py-0 my-0">
              <span class="my-title">{{ demo.solucion }}</span>
            </v-col>
          </v-row>
        </div>
      </figcaption>
      <!-- <figcaption class="info-caption" @click="openLink(demo.enlace.route)"> -->
      <figcaption class="info-caption">
        <span v-html="demo.descripcion" class="mb-3"></span>
        <v-row>
          <v-col>
            <v-btn
              color="orange orange lighten-1"
              @click="openLink(demo.enlace.route)"
            >
              Ver demo
            </v-btn>
          </v-col>
          <v-col v-if="demo.youtubeId">
            <v-btn color="orange orange lighten-1" @click="showModal">
              Ver video
            </v-btn>
          </v-col>
        </v-row>
      </figcaption>
    </figure>
    <v-dialog
      v-if="demo.youtubeId"
      v-model="showVideo"
      class="d-flex"
      content-class="demo-video"
      :max-width="videoWidth"
    >
      <v-btn fab class="close-btn" small @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <youtube
        :video-id="demo.youtubeId"
        :player-width="videoWidth"
        :player-vars="playerVars"
        @ready="playerReady"
        @ended="closeModal"
      ></youtube>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "NewCardDemo",
  props: {
    demo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cardHover: false,
      showVideo: false,
      videoWidth: 720,
      playerVars: {
        autoplay: 1,
        disablekb: 1,
        controls: 0,
        fs: 0,
        showinfo: 1,
        rel: 0,
        iv_load_policy: 3,
      },
    };
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    playerReady(event) {
      this.player = event.target;
    },
    showModal() {
      this.showVideo = true;
    },
    closeModal() {
      this.showVideo = false;
      this.player.stopVideo();
    },
    switchHover() {
      this.cardHover = !this.cardHover;
    },
  },
};
</script>
