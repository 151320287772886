<template>
  <main>
    <perfect-scrollbar>
      <v-list class="transparente">
        <v-list-item>
          <v-list-item-content class="vlc">
            <v-alert border="left" icon="mdi-robot" elevation="24" color="white">
              <p class="parrafo black--text">Hola muy buen día, estoy para ayudarte</p>
            </v-alert>
          </v-list-item-content>
        </v-list-item>
        <template v-for="item in mensajes">
          <v-list-item v-if="item.from == 'Asistente'" :key="item.id" class="vliTamaño">
            <v-list-item-content class="vlc">
              <v-alert border="left" icon="mdi-robot" elevation="24" color="white">
                <p class="parrafo black--text" v-html="item.mensaje"></p>
                <v-list-item-subtitle>
                  {{ item.hora }}
                </v-list-item-subtitle>
              </v-alert>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else-if="item.from == 'Usuario'" :key="item.id" class="text-right vliTamaño">
            <v-list-item-content class="vlc">
              <v-alert border="right" elevation="24" color="white">
                <v-row align="center" justify="end">
                  <v-col class="grow black--text" justify="end" >
                    {{ item.mensaje }}
                  </v-col>
                  <v-col class="shrink iconUsuario">
                    <v-icon>mdi-human</v-icon>
                  </v-col>
                </v-row>
              </v-alert>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </perfect-scrollbar>
  </main>
</template>
<script>
export default {
  name: "ChatMensaje",
  props: {
    mensajes: {
      type: Array,
      required: true,
    },
  },
  methods: {},

};
</script>

<style scoped>
.parrafo {
  margin-bottom: 0px;
}
.contenido {
  padding-top: 0px;
  padding-bottom: 0px;
}
.iconUsuario {
  padding-left: 0px;
}
.ps{
   height: 220px;
}
.vlc{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>