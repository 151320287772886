<template>
  <main>
    <v-card class="mx-auto card transparente">
      <v-card-title class="justify-center">
        <h6 class="text white--text">
          {{ datos.nombre }}
        </h6>
      </v-card-title>
      <v-img
        class="tamaño"
        :src="require('../../assets/img/' + datos.imagen)"
      ></v-img>
      <v-card-actions class="justify-center">
        <DocsModal :dato="dato" />
      </v-card-actions>
    </v-card>
  </main>
</template>


<script>
import DocsModal from "../Docs/DocsModal";
export default {
  name: "DocsCard",
  components: {
    DocsModal,
  },
  props: {
    datos: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.dato.push({
      titulo: this.datos.nombre,
      ubi: this.datos.archivo,
      estado: false,
    });
  },
  data() {
    return {
      dato: [],
    };
  },
};
</script>

<style>
.card {
  min-width: 150px;
  width: 150px !important;
  max-height: 350px;
}
.tamaño {
  width: auto !important;
  /* max-width: 100%; */
  height: 190px !important;
}
.text {
  padding-bottom: 5px;
  padding-top: 5px;
  -ms-word-break: break-all;
  word-break: break-all;

  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align: center;
  max-height: 50px;
}
</style>