<template>
  <main>
    <v-list-item class="vliTamaño">
      <v-list-item-content class="contenido">
        <v-alert border="left" icon="mdi-robot" elevation="5" outlined>
          <p class="parrafo">Hola muy buen día, estoy para ayudarte</p>
        </v-alert>
      </v-list-item-content>
    </v-list-item>
    <template v-for="item in mensajes">
      <v-list-item v-if="item.from == 'Asistente'" :key="item.id" class="vliTamaño">
        <v-list-item-content class="contenido">
          <v-alert border="left" icon="mdi-robot" elevation="5" outlined>
            <p class="parrafo" v-html="item.mensaje"></p>
            <v-list-item-subtitle>
              {{ item.hora }}
            </v-list-item-subtitle>
          </v-alert>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else-if="item.from == 'Usuario'" :key="item.id" class="text-right vliTamaño">
        <v-list-item-content class="contenido">
          <v-alert border="right" elevation="5" outlined>
            <v-row align="center" justify="end">
              <v-col class="grow" justify="end">
                {{ item.mensaje }}
              </v-col>
              <v-col class="shrink iconUsuario">
                <v-icon>mdi-human</v-icon>
              </v-col>
            </v-row>
          </v-alert>
        </v-list-item-content>
      </v-list-item>
    </template>
  </main>
</template>
<script>
export default {
  name: "ChatMensaje",
  props: {
    mensajes: {
      type: Array,
      required: true,
    },
  },
  methods: {},

};
</script>

<style scoped>
.parrafo {
  margin-bottom: 0px;
}
.contenido {
  padding-top: 0px;
  padding-bottom: 0px;
}
.iconUsuario {
  padding-left: 0px;
}
</style>