<template>
  <main>
    <div ref="chartdiv"></div>
  </main>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default {
  name: "XYChart",
  props: {
    datosC: {
      type: Object,
      required: true,
    },
  },
  created() {
    
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  mounted() {
    var chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
    chart.data = this.datosC.datos;
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.name = "Web Traffic";
    series.dataFields.categoryX = "country";
    series.dataFields.valueY = "visits";
  },
};
</script>

<style scoped>
</style>