import Vue from 'vue'
import VueRouter from 'vue-router'


import Home2 from '../views/Home2.vue'
// import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Demo1 from '../views/Demos/Demo1.vue'
import AMSA from '../views/Demos/AMSA.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home2',
    component: Home2,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/Demo1',
    name: 'Demo1',
    component: Demo1
  },
  {
    path: '/AMSA',
    name: 'AMSA',
    component: AMSA
  },
  {
    path: '/:ruta',
    component: Vue.component('Home2', require('../views/Home2.vue').default)
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
