<template>
  <main>
    <v-card class="transparenteN">
      <v-card-title class="white--text">Asistente virtual</v-card-title>
      <hr>
      <v-dialog v-model="loading.estado" hide-overlay persistent width="300">
        <v-card :color="loading.color">
          <v-card-text>
            {{ loading.titulo }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      
      <v-card-text id="chatID" class="textCard">
        <ChatMensajeWindow :mensajes="logMessages" />
      </v-card-text>

      <v-card-actions @keypress.enter="enviarMensaje" color="white">
        <v-row>
          <v-col>
            <v-form
              color="white"
              ref="form"
              v-model="validar"
              lazy-validation
              action="javascript:void(0);"
            >
              <div class="d-flex flex-row align-center">
                <v-text-field
                dark
                  v-model="msg"
                  :rules="mensajeRules"
                  label="Escribe tu mensaje"
                  required
                  color="white"
                ></v-text-field>
                <v-btn icon class="ml-4" @click="enviarMensaje"><v-icon color="white">mdi-send</v-icon></v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <!-- Mensaje de error -->
    <!-- Iván: Crear componente propio para esto. -->
    <v-snackbar v-model="errorS" :timeout="timeout" color="red">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="errorS = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </main>
</template>


<style scoped>

.trans {
  background-color: rgba(0, 0, 0, 0) !important;
}
.textCard {
  height: 250px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.v-list {
  padding: 0px;
  margin-left: 16px;
  margin-right: 16px;
}
::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}
</style>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import ChatMensajeWindow from "./ChatMensajeWindow";
export default {
  components: {
    ChatMensajeWindow,
  },
  props: {
    nombre: {
      type: String,
      required: true,
    },
  },

  name: "ChatWindow",
  data: () => ({
    msg: null,
    errorS: false,
    text:
      "Ha ocurrido un error, tranquilo no es culpa tuya, por favor repite la acción.",
    timeout: 3000,
    validar: true,
    mensaje: "",
    mensajeRules: [(v) => !!v || "Necesita escribir un mensaje"],
  }),
  methods: {
    msgNew() {
      const vm = this;
      vm.$emit("cambio");
    },

    ...mapMutations([
      "mostrarLoading",
      "ocultarLoading",
      "setID",
      "saveMsg",
      "entorno",
    ]),
    getDate() {
      return `${new Date().getHours()}:${new Date().getMinutes()}`;
    },
    enviarMensaje() {
      if (this.$refs.form.validate()) {
        this.entorno("Assistant");
        this.dialogoAsistente(this.msg);
      }
    },
    async dialogoAsistente(mensaje) {
      try {
        var aux = "";
        if (this.idConversation != "") {
          aux = JSON.stringify(this.idConversation);
        }
        var data = new FormData();
        data.append("mensaje", mensaje);
        data.append("context", aux);
        data.append("conexion", this.nombre);
        this.mostrarLoading({ titulo: "Espere...", color: "info" });
        await axios.post(this.url, data).then((data) => {
          this.setID(data.data.context);
          data.data.output.text == ""
            ? this.guardarMensajes(
                mensaje,
                "No te he entendido, ¿podrías repetir?",
                data.data
              )
            : this.guardarMensajes(
                mensaje,
                data.data.output.text.toString(),
                data.data
              );
        });
      } catch (error) {
        this.errorS = true;
      } finally {
        this.ocultarLoading();
      }
    },
    async guardarMensajes(usuario, asistente, data) {
      try {
        await this.addArray(Date.now(), "Usuario", usuario, data);
        await this.addArray(Date.now(), "Asistente", asistente, data);
      } catch (error) {
        this.errorS = true;
      }
      this.msg = "";
      this.$refs.form.resetValidation();
      this.msgNew();
    },
    async addArray(id, from, mensaje, data) {
      try {
        await this.saveMsg({
          id: id,
          from: from,
          mensaje: mensaje,
          datos: data,
          hora: this.getDate(),
        });
      } catch (error) {
        this.errorS = true;
      } finally {
      }
    },
  },
  computed: {
    ...mapState(["loading", "idConversation", "logMessages", "url"]),
  },
  beforeUpdate() {
    var aux = document.getElementById("chatID");
    if (aux) {
      aux.scrollTop += aux.scrollHeight;
    }
  },
};
</script>

