<template>
  <main>
    <div ref="chartdiv"></div>
  </main>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
export default {
  name: "ChartExample",
  props: {
    datosC: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    var chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
    chart.data = this.datosC.datos;

    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "litres";
    series.dataFields.category = "country";
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
</style>