<template>
  <!-- <perfect-scrollbar> -->
    <div>
      <template v-if="!$route.meta.allowAnonymous">
        <v-app id="inspire">
          <!-- min height 100vp -->
          <MainAppBar />
          <v-main class="grey lighten-3">
            <div class="app-container">
                <router-view/>
            </div>
          </v-main>
            <Footer/>
        </v-app>
      </template>
      <template v-else>
        <transition>
          <router-view></router-view>
        </transition>
      </template>
    </div>
  <!-- </perfect-scrollbar> -->
</template>

<script>
import MainAppBar from "./components/MainAppBar";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    MainAppBar,
    Footer,
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style>
.v-btn:hover:before {
  color: transparent !important;
}
/* .ps {
  height: 100vh;
}
::-webkit-scrollbar {
  display: none;
} */
</style>