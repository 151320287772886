import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        inndot1: '#0D0D0D',
        inndot2: '#F2CB05',
        inndot3: '#F2B705',
        inndot4: '#D97904',
        inndot5: '#BF0A19',
        inndot6: '#A60F1B',
        inndot7: '#73171F',
      },
      inndot: {
        inndot1: '#0D0D0D',
        inndot2: '#F2CB05',
        inndot3: '#F2B705',
        inndot4: '#D97904',
        inndot5: '#BF0A19',
        inndot6: '#A60F1B',
        inndot7: '#73171F',
      }
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
