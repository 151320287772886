<template>
  <main>
    <v-row justify="center">
      <v-bottom-sheet
        persistent
        @keydown.esc="cerrarD"
        v-model="openD"
        scrollable
        max-width="450px"
        inset
        content-class="my-custom-dialog"
      >
        <v-card>
          <v-card-title class="tituloTarjeta"
            >Asistente
            <v-spacer></v-spacer>
            <v-btn
              icon
              color="black"
              @click="openD = !openD"
              v-on:click="cerrarD"
            >
              <v-icon color="white" large>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-dialog
            v-model="loading.estado"
            hide-overlay
            persistent
            width="300"
          >
            <v-card :color="loading.color">
              <v-card-text>
                {{ loading.titulo }}
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-card-text id="chatID" class="textCard">
            <v-list>
              <ChatMensaje :mensajes="logMessages" />
            </v-list>
          </v-card-text>
          <v-card-actions @keypress.enter="enviarMensaje">
            <v-row no-gutters>
              <v-col>
                <v-form
                  ref="form"
                  v-model="validar"
                  lazy-validation
                  action="javascript:void(0);"
                >
                  <div class="d-flex flex-row align-center">
                    <v-text-field
                      v-model="msg"
                      :rules="mensajeRules"
                      label="Escribe tu mensaje"
                      required
                      color="secondary"
                    ></v-text-field>

                    <v-btn icon class="ml-4" @click="enviarMensaje"
                      ><v-icon>mdi-send</v-icon></v-btn
                    >
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-snackbar v-model="errorS" :timeout="timeout" color="red">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="black" text v-bind="attrs" @click="errorS = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </v-bottom-sheet>
    </v-row>
  </main>
</template>


<style scoped>
/* Esto es lo que se cambia para el fondo. */
.tituloTarjeta {
  background: -webkit-linear-gradient(left, #f2cb05, #d97904, #a60f1b, #0d0d0d);
}

>>> .my-custom-dialog {
  align-self: flex-end;
  position: absolute;
  bottom: 0%;
  right: 0%;
}

.textCard {
  height: 250px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import ChatMensaje from "./ChatMensaje";
export default {
  components: {
    ChatMensaje,
  },
  name: "ChatPopUp",
  props: {
    openD: {
      type: Boolean,
      required: false,
      default: true,
    },
    nombre: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    msg: null,
    errorS: false,
    text:
      "Ha ocurrido un error, tranquilo no es culpa tuya, por favor repite la acción.",
    timeout: 3000,
    validar: true,
    mensaje: "",
    mensajeRules: [(v) => !!v || "Necesita escribir un mensaje"],
  }),
  methods: {
    ...mapMutations([
      "mostrarLoading",
      "ocultarLoading",
      "setID",
      "saveMsg",
      "entorno",
    ]),
    cerrarD() {
      const vm = this;
      vm.$emit("cerrarD");
    },
    getDate() {
      return `${new Date().getHours()}:${new Date().getMinutes()}`;
    },
    enviarMensaje() {
      if (this.$refs.form.validate()) {
        this.entorno("Assistant");
        this.dialogoAsistente(this.msg);
      }
    },
    async dialogoAsistente(mensaje) {
      try {
        var aux = "";
        if (this.idConversation != "") {
          aux = JSON.stringify(this.idConversation);
        }
        var data = new FormData();
        data.append("mensaje", mensaje);
        data.append("context", aux);
        data.append("conexion", this.nombre);
        this.mostrarLoading({ titulo: "Espere...", color: "info" });
        await axios.post(this.url, data).then((data) => {
          this.setID(data.data.context);
          data.data.output.text == ""
            ? this.guardarMensajes(
                mensaje,
                "No te he entendido, ¿podrías repetir?",
                data.data
              )
            : this.guardarMensajes(
                mensaje,
                data.data.output.text.toString(),
                data.data
              );
        });
      } catch (error) {
        this.errorS = true;
      } finally {
        this.ocultarLoading();
      }
    },
    async guardarMensajes(usuario, asistente, data) {
      try {
        await this.addArray(Date.now(), "Usuario", usuario, data);
        await this.addArray(Date.now(), "Asistente", asistente, data);
      } catch (error) {
        this.errorS = true;
      }
      this.msg = "";
      this.$refs.form.resetValidation();
    },
    async addArray(id, from, mensaje, data) {
      try {
        await this.saveMsg({
          id: id,
          from: from,
          mensaje: mensaje,
          datos: data,
          hora: this.getDate(),
        });
      } catch (error) {
        this.errorS = true;
      }
    },
    changeString(texto) {
      var count = 28,
        auxC = 0,
        res = "";
      texto.split(" ").forEach((element) => {
        auxC += element.length + 1;
        auxC >= count
          ? ((res += element + " " + "<br>"), (count += 28))
          : (res += element + " ");
      });
      return res;
    },
  },
  computed: {
    ...mapState(["loading", "idConversation", "logMessages", "url"]),
  },
  beforeUpdate() {
    var aux = document.getElementById("chatID");
    if (aux) {
      aux.scrollTop += aux.scrollHeight;
    }
  },
};
</script>

